
//import { navigate } from "gatsby"
import {useEffect} from "react";

const Folleto = ({ location }) => {
  //navigate("/catalogo.html")
  useEffect(() => {
    //alert(location.search)
    console.log("DESDE FOLLETO:" + location.search)
    const phone = new URLSearchParams(location.search)?.get("phone");
    window.dataLayer.push({'event': 'catalogView','phone':phone, 'eventTimeout' : 2000,
    'eventCallback': function(id) {
      setTimeout(function(){window.location.href="/Durcal_Producto.pdf"},2000);
    }

    });
    //setTimeout(function(){window.location.href="/Durcal_Producto.pdf"},1000);
   // https://docs.google.com/viewerng/viewer?url=http://yourfile.pdf
    //window.location.href ="/catalogo.html"+location.search
    //navigate("/catalogo.html"+location.search)
  })
  return (
   ""
  )

}

export default Folleto
